import { getToken } from '@/api/api'

const state = {
  token: null
}

const mutations = {
  SET_TOKEN: (state, accessToken) => {
    state.token = accessToken
  }
}

const actions = {
  login({ commit }, params) {
    return new Promise((resolve, reject) => {
      getToken(params)
        .then(response => {
          const { code, data } = response
          console.log(19, data)
          if (code === 0) {
            const { accessToken } = data
            commit('SET_TOKEN', accessToken)
            resolve(data)
          } else {
            reject('登录失败!')
          }
          console.log(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
