// 常量
const CONSTANT = {
  SUBMIT_MEAL_PERIOD: [
    {
      label: '早餐',
      value: 'breakfast'
    },
    {
      label: '午餐',
      value: 'lunch'
    },
    {
      label: '晚餐',
      value: 'dinner'
    },
    {
      label: '夜宵',
      value: 'night'
    }
  ],
  // 消费明细-消费类型
  CONSUME_TYPE: ['食堂', '餐券', '伙食费', '淋浴']
};

export default CONSTANT;
